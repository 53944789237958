/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import uniqid from 'uniqid';
import CryptoJS from 'crypto-js';

import lang from '../lang';
import * as token from './../utils/token';
import { getBanescoEnvironment } from './../utils/banesco';
import { registerBanescoPayment } from '../api/banesco';

/*Style*/

const cMainStyle = {
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  border: 0,
  borderRadius: '8px'
};

const cTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  height: '100%',
  textAlign: 'center'
};

function Banesco({ visible, APIKey, secret, amount, amountUSD, cedula, companyAPIKey, currencyData, zohoInvoices, zohoAdaptedInvoices, zohoCustomerId, metadata, adaptedMetadata, paymentDescription, addressQA, addressPROD, companyLogo, company }) {
  /*State variables*/
  const [btn, setBtn] = useState(null);

  /*Functions*/

  const roundAmount = async (amount) => {
    const formattedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
    return formattedAmount;
  };

  const setUserSessionData = async (transactionID) => {
    const publicName = company;
    const logo = companyLogo;
    const usdAmount = amountUSD;

    const jsn = {
      "publicName": publicName,
      "companylogo": logo,
      "transactionId": transactionID,
      "amountUSD": usdAmount
    };

    const encodedString = CryptoJS.enc.Utf8.parse(JSON.stringify(jsn));
    const base64Encoded = CryptoJS.enc.Base64.stringify(encodedString);

    token.setToken("paymentPayload", base64Encoded);
  };

  const drawBanescoButton = async () => {
    //Banesco data for button
    const apiKey = APIKey;

    // const valor1Cedula = (cedula) ? cedula : "";
    const valor1Cedula = "";
    const valor2Monto = (await roundAmount(amount)).toFixed(2);
    let valor3CampoDinamico = "";
    const valor4IDTransaccion = uniqid();
    const valor5Descripcion = paymentDescription;

    const banescoSecret = secret;

    const firmar = `${apiKey}${valor1Cedula}${valor2Monto}${valor4IDTransaccion}`;

    const firma = CryptoJS.HmacSHA256(firmar, banescoSecret).toString(CryptoJS.enc.Hex);

    const environment = await getBanescoEnvironment();

    var URL_BANESCO = (environment === 'production') ? addressPROD : addressQA;

    //Data for payment registry
    const exchangeRate = currencyData.filter(currency => currency.CurrencyID === 2)[0].ExchangeBs;

    const banescoPayment = {
      "transaction_id": valor4IDTransaccion,
      "identification_card": cedula,
      "exchange_rate": exchangeRate,
      "amount_USD": amountUSD,
      "amount_Bs": valor2Monto,
      "zoho_invoices": JSON.stringify(zohoInvoices),
      "zoho_adapted_invoices": JSON.stringify(zohoAdaptedInvoices),
      "zoho_customer_id": zohoCustomerId,
      "metadata": JSON.stringify(metadata),
      "adapted_metadata": JSON.stringify(adaptedMetadata),
      "environment": environment
    };

    await registerBanescoPayment(companyAPIKey, banescoPayment);

    //Data for user session
    await setUserSessionData(valor4IDTransaccion);

    //Banesco button
    var boton = await window.Banesco_boton(URL_BANESCO, valor1Cedula, valor2Monto, valor3CampoDinamico, valor4IDTransaccion, valor5Descripcion, apiKey, firma);

    setBtn(boton);
  };

  useEffect(() => {
    drawBanescoButton();
  }, []);


  return (
    <>
      {
        (visible === true) ?
          <Card style={cMainStyle} className="mt-3">
            <CardBody>

              <CardTitle style={cTitleStyle}>{lang("BANESCO_BUTTON")}</CardTitle>

              <div dangerouslySetInnerHTML={{ __html: btn }} style={{ textAlign: 'center' }}></div>

            </CardBody>
          </Card>
        :
        <></>
      }
    </>
  );
}

export default Banesco;