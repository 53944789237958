import React from 'react';
import { Spinner } from 'reactstrap';

function PageSpinner() {

  const contStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  };

  return (
    <div style={contStyle}>
      <Spinner type="border" children="" />
    </div>
  );

}

export default PageSpinner;