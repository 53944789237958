import React from 'react';
import { Spinner } from 'reactstrap';

function BtnSpinner() {

  return (
    <Spinner type="border" children="" size="sm"/>
  );

}

export default BtnSpinner;