import React, { useState, useRef } from 'react';
import { Row, Col, Input, Card, CardBody, CardTitle, Button } from 'reactstrap';
import Select from 'react-select';
import uniqid from 'uniqid';
import BtnSpinner from './BtnSpinner';
import lang from '../lang';
import { registerBNCPayment } from '../api/bnc';
import BNCLogo from '../assets/icons/bnc-logo.png';

/*Styles*/
const cardStyle = {
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  marginTop: '16px',
  border: 0,
  borderRadius: '8px',
};

const cardTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  height: '100%',
  textAlign: 'center',
};

const labelStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  marginBottom: 0,
  marginTop: '10px',
};

function BNC({
  visible,
  amount,
  amountUSD,
  companyAPIKey,
  currencyData,
  zohoInvoices,
  zohoAdaptedInvoices,
  email,
  zohoCustomerId,
  zohoCustomerName,
  zohoCustomerLastName,
  metadata,
  adaptedMetadata,
  company,
  companyColor,
  notify,
  reset,
  BNCBankList,
}) {
  /* Values for the payment */
  const [loading, setLoading] = useState(false);
  const [BeneficiaryCellPhone, setBeneficiaryCellPhone] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [clientType, setClientType] = useState('');
  const [BeneficiaryID, setBeneficiaryID] = useState('');
  const [BeneficiaryPaymentReference, setBeneficiaryPaymentReference] = useState('');
  const [bankCode, setBankCode] = useState('');
  const Amount = amount;

  /* Pago móvil elements reference */
  const pagoMovilCellPhoneRef = useRef(null);
  const pagoMovilRifRef = useRef(null);

  /*Variables*/
  const options = [
    { value: 'V', label: 'V' },
    { value: 'E', label: 'E' },
    { value: 'J', label: 'J' },
    { value: 'G', label: 'G' },
  ];

  const typeofNumber = [
    { value: '58412', label: '412' },
    { value: '58414', label: '414' },
    { value: '58424', label: '424' },
    { value: '58416', label: '416' },
    { value: '58426', label: '426' },
  ];

  const regExpCellPhone = /^\d{7}$/;

  const regExpId = /^\d{7,9}$/;

  const exchangeRate = currencyData.filter((currency) => currency.CurrencyID === 2)[0].ExchangeBs;

  /*Functions*/
  const roundAmount = (amount) => {
    const formattedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
    return formattedAmount;
  };

  const cleanFields = () => {
    setBeneficiaryCellPhone('');
    setClientType('');
    setBeneficiaryID('');
    setBeneficiaryPaymentReference('');
  };

  const confirmBNCPayment = async () => {
    // Data validation for the payment
    if (!BeneficiaryCellPhone) {
      notify(lang('BNC_ENTER_CELL_PHONE'), 'info', 'colored');
    } else if (!clientType) {
      notify(lang('BNC_SELECT_CLIENT_TYPE'), 'info', 'colored');
    } else if (!BeneficiaryID) {
      notify(lang('BNC_ENTER_ID'), 'info', 'colored');
    } else if (!BeneficiaryPaymentReference) {
      notify(lang('BNC_PAYMENT_REFERENCE'), 'info', 'colored');
    } else if (!String(BeneficiaryCellPhone).match(regExpCellPhone)) {
      notify(lang('BNC_INVALID_CELL_PHONE'), 'error', 'colored');
    } else if (!phoneType) {
      notify(lang('BNC_ENTER_OPERATOR'), 'info', 'colored');
    } else if (!String(BeneficiaryID).match(regExpId)) {
      notify(lang('BNC_INVALID_ID'), 'error', 'colored');
    } else if (!bankCode) {
      notify(lang('BNC_ENTER_BANKNAME'), 'error', 'colored');
    } else {
      // Loader for the confirm button
      setLoading(true);

      // Data for payment registry, Building the object to register the payment
      const BNCPaymentData = {
        transaction_id: uniqid(),
        exchange_rate: exchangeRate,
        amount_USD: amountUSD,
        amount_Bs: roundAmount(Amount).toFixed(2),
        zoho_invoices: JSON.stringify(zohoInvoices),
        zoho_adapted_invoices: JSON.stringify(zohoAdaptedInvoices),
        zoho_customer_id: zohoCustomerId,
        zoho_customer_email: email,
        zoho_customer_name: zohoCustomerName,
        zoho_customer_last_name: zohoCustomerLastName,
        metadata: JSON.stringify(metadata),
        adapted_metadata: JSON.stringify(adaptedMetadata),
        beneficiary_client_id: clientType.trim() + BeneficiaryID.trim().padStart(9, '0'),
        beneficiary_cell_phone: phoneType.trim() + BeneficiaryCellPhone.trim(),
        beneficiary_payment_reference: BeneficiaryPaymentReference,
        bank_code: bankCode,
      };

      const response = await registerBNCPayment(companyAPIKey, BNCPaymentData);

      if (response.status === 200) {
        cleanFields();
        notify(lang('BNC_SUCCESS'), 'success', 'colored');
        setLoading(false);
        reset();
      } else if (response.status === 400) {
        notify(lang('BNC_ERROR'), 'error', 'colored');
        setLoading(false);
      } else {
        notify(lang('BNC_PAYMENT_EXISTS'), 'error', 'colored');
        setLoading(false);
      }
    }
  };

  const textToClipboard = (type) => {
    if (type === 'cellphone') {
      return navigator.clipboard.writeText(pagoMovilCellPhoneRef.current.innerText.slice(5));
    }
    navigator.clipboard.writeText(pagoMovilRifRef.current.innerText.slice(2));
  };

  return (
    <>
      {visible === true ? (
        <>
          <Card style={cardStyle} className="mt-3">
            <CardBody>
              <Row>
                <Col className="text-center" xs={4}>
                  <img src={BNCLogo} alt="BNC" height={95} />
                </Col>
                <Col>
                  <Row>
                    <CardTitle style={cardTitleStyle}>
                      <strong>BNC</strong> Banco Nacional de Crédito
                    </CardTitle>
                  </Row>
                  <Row className="text-center mt-2">
                    <div>
                      <span ref={pagoMovilCellPhoneRef}>0412-3850480</span>
                      <i
                        className="fa-solid fa-copy"
                        style={{ color: '#454545', marginLeft: 5, cursor: 'pointer' }}
                        onClick={() => textToClipboard('cellphone')}
                      ></i>
                    </div>
                  </Row>
                  <Row className="text-center mt-1">
                    <div>
                      <span ref={pagoMovilRifRef}>J-409582647</span>
                      <i
                        className="fa-solid fa-copy"
                        style={{ color: '#454545', marginLeft: 15, cursor: 'pointer' }}
                        onClick={() => textToClipboard('rif')}
                      ></i>
                    </div>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={cardStyle} className="mt-3">
            <CardBody>
              <CardTitle style={cardTitleStyle}>{lang('BNC_CONFIRMATION')}</CardTitle>
              <Row>
                <Col>
                  <label style={labelStyle}>{lang('TYPE_NUMBER')}</label>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Select
                    placeholder={lang('TYPE_OF_OPERATOR')}
                    options={typeofNumber}
                    onChange={(opt) => setPhoneType(opt.value)}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    placeholder="Ej: 4425475"
                    value={BeneficiaryCellPhone}
                    onChange={(e) => setBeneficiaryCellPhone(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label style={labelStyle}>{lang('USER_ID')}:</label>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <Select
                    placeholder={lang('CLIENT_TYPE')}
                    options={options}
                    defaultValue={clientType}
                    onChange={(opt) => setClientType(opt.value)}
                  />
                </Col>

                <Col xs={8}>
                  <Input
                    value={BeneficiaryID}
                    placeholder="Ej: 25958247"
                    onChange={(event) => setBeneficiaryID(event.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label style={labelStyle}>{lang('BANK_NAME')}:</label>

                  <Input type="select" onChange={(e) => setBankCode(e.target.value)}>
                    <option value="">{lang('SELECT')}</option>
                    {BNCBankList.map((item, index) => (
                      <option key={index} value={item.Code}>
                        {item.Name}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label style={labelStyle}>{lang('BENEFICIARY_PAYMENT_REFERENCE')}</label>

                  <Input
                    placeholder="Ej: 8821941789"
                    value={BeneficiaryPaymentReference}
                    onChange={(e) => setBeneficiaryPaymentReference(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <label style={labelStyle}>{lang('AMOUNT')}</label>

                  <Input disabled value={amount} />
                </Col>
              </Row>
              <Row className="text-center mt-3">
                <Col>
                  <Button style={{ backgroundColor: companyColor }} onClick={() => confirmBNCPayment()}>
                    {loading === true ? <BtnSpinner /> : lang('CONFIRM')}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default BNC;
