/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Row, Col, Button, Input, Card, CardBody, CardTitle } from 'reactstrap';
import BtnSpinner from './BtnSpinner';
import ReCAPTCHA from 'react-google-recaptcha';


function FirstCard({ mainTitle, btnStyle, cardTitle, getInvoices, loading, currency }) {

  /*State variables*/
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [captcha, setCaptcha] = useState(false);

  const gCaptcha = React.useRef(null);

  const regEx = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

  /*Styles*/
  const paymentTitle = {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 600,
    color: '#41434E',
    display: 'block',
    marginTop: '17px',
    marginBottom: '17px',
  };

  const cardStyle = {
    boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
    paddingBottom: '0px',
    border: 0,
    borderRadius: '8px'
  };

  const emailTitle = {
    fontFamily: 'Montserrat-SemiBold, sans-serif',
    fontWeight: 600,
    fontSize: '18px',
    color: '#41434E',
    marginBottom: 0
  };

  const activeButton = {
    backgroundColor: btnStyle.color,
    fontColor: btnStyle.fontColor,
    fontWeight: 'bold',
    width: "100%",
    border: 'none'
  };

  const disabledButton = {
    backgroundColor: '#707070',
    width: "100%",
    border: 'none'
  };

  /*Functions*/
  const handleChange = (userEmail) => {
    setEmail(userEmail);

    (regEx.test(userEmail) === true) ? setDisabled(false) : setDisabled(true);
  };

  const verifyCaptcha = () => {
    (gCaptcha.current.getValue()) ? setCaptcha(true) : setCaptcha(false);;
  };

  return (
    <>
      <Row className="row justify-content-md-center">
        <Col className="text-center">
          <label style={paymentTitle}>{mainTitle}</label>
        </Col>
      </Row>

      <Card style={cardStyle}>

        <CardBody>
          <CardTitle style={emailTitle}>{cardTitle}</CardTitle>

          <Input
            className="mb-2"
            placeholder="Ingresa tu e-mail"
            onChange={(val) => handleChange(val.target.value)}
          />

        {
          (captcha === false) ?
          <Row>
            <Col className="d-flex justify-content-center">
              <ReCAPTCHA
                ref={gCaptcha}
                sitekey="6LfZnfIdAAAAAKLiZ7PFXJapGFGSsGXDLyOuOgXh"
                onChange={verifyCaptcha}
              />
            </Col>
          </Row>
          :
          <></>
        }

          <Button
            className="mt-2"
            disabled={(disabled === false && captcha === true) ? false : true}
            onClick={() => getInvoices(email.trim(), currency, 1)}
            style={(disabled === false && captcha === true) ? activeButton : disabledButton}
          >
            {
              (loading === true) ?
              <BtnSpinner />
              :
              btnStyle.text
            }
          </Button>

        </CardBody>
      </Card>
    </>
  );
}

export default FirstCard;