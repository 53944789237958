import axios from "axios";
import * as CONS from "./constants";
import { setHeaders } from './../utils/headers';

//Company
export async function getCompanyData(apiKey) {
  try {
    const response = await axios.get(`${CONS.company}/${apiKey}`, { headers: await setHeaders() });
    return response;
  }
  catch (error) {
    return error.response;
  }
}

//Company currencies

// export async function getCompanyCurrencies(publicName) {
//   try {
//     const response = await axios.get(`${CONS.currency}/${publicName}`, { headers: await setHeaders() });
//     return response;
//   }
//   catch (error) {
//     return error.response;
//   }
// }

//Status
// export async function getStatusData(tracking) {
//   try {
//     const response = await axios.get(`${CONS.status}/${tracking}`, { headers: await setHeaders() });
//     return response;
//   }
//   catch (error) {
//     return error;
//   }
// }
