var BASE_URL;
const URL = window.location.href;

const local = String(URL).includes('localhost');

if (local) {
  BASE_URL = 'http://localhost:7071/api';
  //BASE_URL = 'https://gopayments-back.azurewebsites.net/api';
}
else {
  const dev = String(URL).includes('dev');

  if (dev) {
    BASE_URL = 'https://gopayments-back-dev.azurewebsites.net/api';
  }
  else {
    BASE_URL = 'https://gopayments-back.azurewebsites.net/api';
  }
}

export const company            = `${BASE_URL}/company`;
export const invoices           = `${BASE_URL}/invoices`;
export const registerBanesco    = `${BASE_URL}/banescoRegister`;
export const registerUbiipagos  = `${BASE_URL}/ubiipagosRegister`
export const registerBNC        = `${BASE_URL}/bncRegister`;

// export const currency = `${BASE_URL}/currency`;
// export const status = `${BASE_URL}/status`;
// export const registerNewPayment = `${BASE_URL}/payment`;