import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Header({companyLogo}) {

  const headerStyle = {
    backgroundColor: '#ffffff',
    height: 'auto',
    borderBottom: '1px solid #707070',
    padding: '17px'
  };

  const imgStyle = {
    width: '275px',
    marginTop: '11px',
    marginBottom: '11px'
  };

  return(

    <Container style={headerStyle} fluid>
      <Row className="text-center">
        <Col>
          <img style={imgStyle} alt='company_logo' src={companyLogo}/>
        </Col>
      </Row>
    </Container>

  );
}

export default Header;