import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getEnviroment } from './utils/environment';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fontawesome-free-6.1.1-web/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/base.css';
import './index.css';

// Components
import Invoices from './views/Invoices';
import NotFound from './views/NotFound';
import Thanks from './views/Thanks';
import Failed from './views/Failed';


Sentry.init({
  dsn: "https://45b0b60ecc7e436d9476fc2eb0c6af22@o1137737.ingest.sentry.io/6194310",
  integrations: [new BrowserTracing()],
  environment: getEnviroment(),
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

    <BrowserRouter>
      <Routes>
        <Route path="/:publicName" element={<Invoices />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/thanks" element={<Thanks />}></Route>
        <Route path="/failed" element={<Failed />}></Route>
      </Routes>
    </BrowserRouter>

  </React.StrictMode>
);
