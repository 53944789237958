import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import lang from '../lang/lang';

const cardStyle = {
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  border: 0,
  borderRadius: '8px',
  width: '100%'
};


function UbiipagosNotActive() {
  return (
    <Card style={cardStyle} className="mt-3">
      <CardBody>

        <Row className="justify-content-md-center">
          <Col className="text-center">
            {lang("UBIIPAGOS_NOT_ACTIVE")}
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
}

export default UbiipagosNotActive;