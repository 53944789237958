/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, CardTitle, List, Button } from 'reactstrap';

import Header from './../components/Header';

import ThanksImage from './../assets/icons/thanks-icon.svg';

import lang from '../lang';

import * as token from './../utils/token';


function Thanks() {
  /*State variables*/
  const [publicName, setPublicName] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [amountUSD, setAmountUSD] = useState(null);

  /*Styles*/
  const mainContainer = {
    width: '100%',
    maxWidth: '480px',
    margin: '0 auto',
    borderRadius: '2px',
    backgroundColor: '#F3F3F7',
    marginTop: '30px',
    display: 'flex'
  };

  const cardStyle = {
    boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
    paddingBottom: '0px',
    border: 0,
    borderRadius: '8px',
    padding: '20px'
  };

  const successIconStyle = {
    width: '200px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: '20px'
  };

  const messageStyle = {
    fontFamily: 'OpenSans-Bold, sans-serif',
    fontWeight: 600,
    fontSize: '20px',
    color: '#41434E',
    marginBottom: '30px'
  };

  const labelStyle = {
    fontFamily: 'OpenSans-Bold, sans-serif',
    fontWeight: 600,
    fontSize: '15px',
    color: '#41434E'
  };

  const continueStyle = {
    backgroundColor: '#0071BC',
    width: '100%'
  };

  /*Functions*/
  const roundAmount = async (amount) => {
    const formattedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
    return formattedAmount;
  };

  const getPaymentDetails = async () => {
    const { name, logo, transaction, amount } = token.getPaymentDetails();
    setPublicName(name);
    setCompanyLogo(logo);
    setTransactionId(transaction);
    setAmountUSD((await roundAmount(amount)).toFixed(2));
  };

  const continueToHome = () => {
    window.location.href = `/${publicName}`;
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <>
      <Header companyLogo={companyLogo} />

      <Container style={mainContainer}>

        <Card style={cardStyle}>
          <CardTitle>
            <img alt="..." src={ThanksImage} style={successIconStyle} />
          </CardTitle>

          <CardBody>
            <div className="text-center">
              <label style={messageStyle}> {lang("PAYMENT_SUCCESS")} </label>
            </div>

            <List type="unstyled">
              <div>
                <li>
                  <label style={labelStyle}> {lang("AMOUNT")}: </label>
                  <label className="float-end"> {amountUSD} $</label>
                </li>

                <li className="mt-4">
                  <label style={labelStyle}> {lang("BANK_REFERENCE")} </label>
                  <label className="float-end" style={{ color: "#0071BC", fontWeight: 'bold' }}> {transactionId} </label>
                </li>
              </div>
            </List>
          </CardBody>

          <Button style={continueStyle} onClick={continueToHome}>{lang("CONTINUE")}</Button>
        </Card>
      </Container>
    </>
  );
}

export default Thanks;