import React from 'react';

import { Row, Col, Input, Table, Card, CardBody, CardTitle } from 'reactstrap';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import lang from '../lang';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

function SecondCard({ visible, title, invoices, totalTitle, companyColor, totalAmount, total, currencySymbol }) {

  /*Styles*/
  const cMainStyle = {
    boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
    paddingBottom: '0px',
    border: 0,
    borderRadius: '8px'
  };

  const cTitleStyle = {
    fontFamily: 'OpenSans-Medium, sans-serif',
    color: '#41434E',
    textAlign: 'center',
    height: '100%'
  };

  const tTitleStyle = {
    display: 'block',
    marginBottom: '-10px',
    textAlign: 'center',
    fontWeight: 'bold'
  };

  const tStyle = {
    color: companyColor,
    fontFamily: 'Montserrat-SemiBold, sans-serif',
    fontWeight: 600,
    fontSize: '48px',
    textAlign: 'center',
    marginBottom: 0
  };


  /*Functions*/
  const getFullDescription = (invoice) => {
    const { number, date, due_date: dueDate } = invoice;

    const description = `${number} ${dayjs(date).utc().format('MMM D')} Vence ${dayjs(dueDate).utc().format('MM/DD')}`

    return description;
  };

  const selectAll = (check) => {
    var checkControl = check.target.checked;
    var checkBoxes = document.getElementsByClassName("checkList");

    for (let checkBox of checkBoxes) {
      checkBox.checked = checkControl;
    }
    totalAmount();
  };

  const roundAmount = (amount) => {
    const formattedAmount = Math.round((amount + Number.EPSILON) * 100) / 100;
    return formattedAmount;
  };


  return (
    <>
      {
        (visible === true) ?
          <Card style={cMainStyle} className="mt-3">

            <CardBody>
              <CardTitle style={cTitleStyle}> {title} </CardTitle>

              <div className="text-center">

                <Table className="table-borderless text-center table-sm" responsive>
                  <thead>
                    <tr>
                      <td>
                        <Input
                          type="checkbox"
                          id="chk-control"
                          onChange={(val) => selectAll(val)}
                        />
                      </td>

                      <td className="text-left">
                        <strong> {lang("INVOICES")} </strong>
                      </td>

                      <td className="text-right">
                        <strong>{lang("AMOUNT")}</strong>
                      </td>

                    </tr>
                  </thead>

                  <tbody id="tableBody">
                    {
                      (invoices) ?
                        invoices.map((invoice, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                id={`chk-${index}`}
                                className="form-check-input checkList"
                                type="checkbox"
                                onChange={() => totalAmount()}
                              />
                            </td>
                            <td>{getFullDescription(invoice)}</td>
                            <td>{currencySymbol + " " + roundAmount(invoice.balance).toFixed(2)}</td>
                          </tr>
                        ))
                        :
                        <></>
                    }
                  </tbody>
                </Table>
              </div>

              <Row className="justify-content-md-center">
                <Col className="text-center">
                  <label style={tTitleStyle}>{totalTitle}</label>
                </Col>
              </Row>

              <Row className="justify-content-md-center">
                <Col className="text-center">
                  <label style={tStyle}>{(total === 0) ?  `${currencySymbol} 0.00` : `${currencySymbol} ${roundAmount(total).toFixed(2)}`}</label>
                </Col>
              </Row>

            </CardBody>

          </Card>
        :
        <></>
      }
    </>
  );

}

export default SecondCard;