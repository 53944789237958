import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import lang from '../lang';

function NotFound() {
  /*Styles*/
  const notFound = {
    display: 'block',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  };

  const title = {
    fontSize: '250px',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  };

  const advise = {
    fontSize: '35px'
  };

  return (
    <Container style={notFound}>

      <Row>
        <Col className="text-center">
          <label style={title}>404</label>
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <label style={advise}>{lang("PAGE_NOT_FOUND")}</label>
        </Col>
      </Row>

    </Container>
  );
}

export default NotFound;