import axios from "axios";
import * as CONS from "./constants.js";
import { setHeaders } from './../utils/headers';

export async function getInvoices(email, apiKey, currencyActiveOption) {

  const headers = await setHeaders();
  headers["api_key"] = apiKey;
  headers["currency"] = currencyActiveOption;

  try {
    const response = await axios.get(`${CONS.invoices}/${email}`, { headers: headers });
    return response;
  }
  catch (error) {
    return error.response;
  }
}

// export async function registerNewPayment(apiKey, body) {
//   const headers = await setHeaders();
//   headers["api_key"] = apiKey;

//   try {
//     const payment = await axios.post(CONS.registerNewPayment, body, { headers: headers });
//     return payment;
//   }
//   catch (error) {
//     return error.response;
//   }
// }