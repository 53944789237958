export async function getBanescoEnvironment() {
  const URL = window.location.href;

  const local = String(URL).includes('localhost');

  let environment;

  if (local) {
    environment = 'develop';
  }
  else {
    const dev = String(URL).includes('dev');

    if (dev) {
      environment = 'develop';
    }
    else {
      environment = 'production';
    }
  }

  return environment;
}