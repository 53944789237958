import React from 'react';

function Footer() {
  
  const footerStyle = {
    color: '#41434E',
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '20px',
    marginBottom: '20px'
  };

  return (
    <div className="text-center" style={footerStyle}>
      <span>Powered by Equaldata Corp.</span>
    </div>
  );
}

export default Footer;