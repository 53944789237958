export function getEnviroment() {
  const URL = window.location.href;
  const local = String(URL).includes('localhost');

  if (local) {
    return 'local';
  }
  else {
    const dev = String(URL).includes('dev');

    if (dev) {
      return 'develop';
    }
    else {
      return 'production';
    }
  }
};

export async function getEnvironment() {
  const URL = window.location.href;

  const local = String(URL).includes('localhost');

  if (local) {
    return 'local';
  }
  else {
    const dev = String(URL).includes('dev');

    if (dev) {
      return 'develop';
    }
    else {
      return 'production';
    }
  }
};