/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Button } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Components
import Header from './../components/Header.js';
import Footer from './../components/Footer.js';
import PageSpinner from './../components/PageSpinner.js';
import FirstCard from './../components/FirstCard.js';
import CurrencyCard from './../components/CurrencyCard.js';
import SecondCard from './../components/SecondCard.js';
import PaymentMethod from '../components/PaymentMethod.js';
import BanescoConfirmation from './../components/BanescoConfirmation.js';
import Banesco from './../components/Banesco.js';
import BanescoNotActive from './../components/BanescoNotActive';
import UbiipagosD from '../components/UbiipagosD.js';
import UbiipagosP from '../components/UbiipagosP.js';
import UbiiNotActive from './../components/UbiiNotActive';
import BNC from './../components/BNC.js';
import BNCNotActive from '../components/BNCNotActive.js';

// API
import * as company from './../api/company.js';
import * as invoice from './../api/invoices.js';
import * as token from './../utils/token';

//Lang
import lang from '../lang';

//Utils
import { getEnvironment } from './../utils/environment';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

/*Styles*/
const mainContainer = {
  width: '100%',
  maxWidth: '480px',
  margin: '0 auto',
  borderRadius: '2px',
  backgroundColor: '#F3F3F7',
};

function Invoices() {
  /*State variables*/

  //Company public name
  const { publicName } = useParams();

  //Company data, variables for dynamic styling
  const [companyData, setCompanyData] = useState({});
  const [fetchBtn, setFetchBtn] = useState({});

  // Ubiipagos config
  const [ubiipagosConfig, setUbiipagosConfig] = useState({});

  //Main variables
  const [email, setEmail] = useState('');

  //Invoices for main currency, adapted currency, and metadata according to currency
  const [invoices, setInvoices] = useState([]);
  const [adaptedInvoices, setAdaptedInvoices] = useState([]);
  const [bsInvoices, setBsInvoices] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [adaptedMetadata, setAdaptedMetadata] = useState({});

  //Total in the adapted currency, USD for Zoho
  const [total, setTotal] = useState(0);
  const [totalUSD, setTotalUSD] = useState(0);
  const [totalBs, setTotalBs] = useState(0);

  //Control variables
  const [loading, setLoading] = useState({ page: true, fetch: false, currencyFetch: false });
  const [visible, setVisible] = useState({
    cardOne: true,
    cardTwo: false,
    cardCurrency: false,
    cardBanesco: false,
    banescoConfirm: false,
    method: false,
    Ubiipagos: false,
    GoBack: false,
    BNC: false,
  });

  //Variables for currency
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [currencyActiveOption, setCurrencyActiveOption] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState('');

  //Variables for payment methods
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(0);
  const [confirmedMethod, setConfirmedMethod] = useState('');

  /*Company variables from configuration*/
  const {
    api_key: apiKey,
    company_color: companyColor,
    email_label: emailLabel,
    invoices_label: invoicesLabel,
    logo_url: logoUrl,
    total_label: totalLabel,
    window_title: windowTitle,
    banesco_apikey: BanescoAPIKey,
    banesco_secret: BanescoSecret,
    banesco_address_QA: BanescoPaymentAddressQA,
    banesco_address_PROD: BanescoPaymentAddressPROD,
    banesco_payment_description: BanescoPaymentDescription,
    banesco_is_active: BanescoActive,
  } = companyData;

  //User data for banesco payments
  const [userId, setUserId] = useState(null);

  // Config Data and User data for BNC payments
  const [BNCActive, setBNCActive] = useState(0);
  const [BNCBankList, setBNCBankList] = useState([]);

  //Variables to keep track of selected invoices for a client's payment
  const [zohoInvoices, setZohoInvoices] = useState([]);
  const [zohoAdaptedInvoices, setZohoAdaptedInvoices] = useState([]);
  const [zohoBsInvoices, setZohoBsInvoices] = useState([]);
  const [zohoCustomerId, setZohoCustomerId] = useState('');
  const [zohoCustomerName, setZohoCustomerName] = useState('');
  const [zohoCustomerLastName, setZohoCustomerLastName] = useState('');

  //Variable to set current Environment
  const [environment, setEnvironment] = useState('');

  /*Functions*/

  useEffect(() => {
    handleEnvironment();
    getCompanyData();
    token.deleteToken('paymentPayload');
  }, [email]);

  const handleEnvironment = async () => {
    //Define current environment
    const environment = await getEnvironment();
    setEnvironment(environment);
  };

  /* First Card */
  const getCompanyData = async () => {
    //Obtain all data for the application to start
    const response = await company.getCompanyData(publicName);

    const data = response.data;
    console.log('Data ==>', data);

    const { companyData, companyCurrencies, companyMethods, ubiipagosConfig, bncConfig } = data;

    const { fetch_button } = companyData;

    if (response.status === 200) {
      setCompanyData(companyData);
      setFetchBtn(JSON.parse(fetch_button));

      setCurrencyData(companyCurrencies.currenciesData);
      setCurrencyOptions(companyCurrencies.processedData);

      setPaymentMethods(companyMethods);

      setUbiipagosConfig(ubiipagosConfig);

      setBNCActive(bncConfig.BNCActive);
      setBNCBankList(bncConfig.bankList);

      setLoading({ ...loading, page: false });
    } else {
      window.location.href = './../';
    }
  };

  const retrieveInvoices = async (userEmail, invoicesCurrency, event) => {
    setTotal(0);

    setTotalUSD(0);

    setTotalBs(0);

    setSelectedMethod(0);

    setConfirmedMethod('');

    setEmail(userEmail);

    event === 1
      ? setVisible({
          ...visible,
          cardCurrency: false,
          cardTwo: false,
          banescoConfirm: false,
          cardBanesco: false,
          method: false,
          Ubiipagos: false,
          GoBack: false,
        })
      : setVisible({
          ...visible,
          cardCurrency: true,
          cardTwo: false,
          banescoConfirm: false,
          cardBanesco: false,
          method: false,
          Ubiipagos: false,
          GoBack: false,
        });

    event === 1 ? setLoading({ ...loading, fetch: true }) : setLoading({ ...loading, currencyFetch: true });

    const response = await invoice.getInvoices(userEmail, apiKey, invoicesCurrency);

    const { status, data } = response;

    if (status === 200) {
      // console.log("FACTURAS ===>", response);

      //Make sure to reset everything in second card
      await checkedFalse();

      let invoices = [...data.invoices];
      let adaptedInvoices = data.adapted_invoices !== null ? [...data.adapted_invoices] : null;
      let bsInvoices = data.bs_invoices !== null ? [...data.bs_invoices] : null;
      let invoicesData = { ...data };
      let adaptedInvoicesData = { ...data };
      delete invoicesData['adapted_invoices'];
      delete adaptedInvoicesData['invoices'];

      setInvoices(invoices);
      setAdaptedInvoices(adaptedInvoices);
      setBsInvoices(bsInvoices);
      setMetadata(invoicesData);
      setAdaptedMetadata(adaptedInvoicesData);
      setZohoInvoices([]);
      setZohoAdaptedInvoices([]);
      setZohoCustomerId(data.customer_id);
      setZohoCustomerName(data.customer_name);
      setZohoCustomerLastName(data.customer_lastName);

      if (data.invoices.length === 0) {
        setLoading({ ...loading, fetch: false });
        setVisible({
          ...visible,
          cardCurrency: false,
          cardTwo: false,
          banescoConfirm: false,
          cardBanesco: false,
          method: false,
          Ubiipagos: false,
          GoBack: false,
        });

        notify(lang('NO_INVOICES'), 'success', 'colored');
      } else {
        setVisible({
          ...visible,
          cardCurrency: true,
          cardTwo: true,
          banescoConfirm: false,
          cardBanesco: false,
          method: false,
          Ubiipagos: false,
          GoBack: false,
        });
        setLoading({ ...loading, fetch: false });
      }
    } else {
      setLoading({ ...loading, fetch: false });
      setVisible({
        ...visible,
        cardCurrency: false,
        cardTwo: false,
        banescoConfirm: false,
        cardBanesco: false,
        method: false,
        Ubiipagos: false,
        GoBack: false,
      });

      setInvoices([]);
      setAdaptedInvoices([]);
      setBsInvoices([]);
      setMetadata({});
      setAdaptedMetadata({});

      setZohoInvoices([]);
      setZohoAdaptedInvoices([]);
      setZohoBsInvoices([]);
      setZohoCustomerId('');
      setZohoCustomerName('');
      setZohoCustomerLastName('');

      if (response.status === 400) {
        notify(lang('INVALID_EMAIL'), 'error', 'colored');
      } else if (response.status === 403) {
        notify(lang('DENIED_REQUEST'), 'error', 'colored');
      } else if (response.status === 404) {
        notify(lang('CLIENT_DOES_NOT_EXIST'), 'error', 'colored');
      } else if (response.status === 409) {
        notify(lang('MULTIPLE_USERS'), 'error', 'colored');
      }

      await checkedFalse();
    }
  };

  /* Second Card */

  //Unselect every checkbox
  const checkedFalse = async () => {
    var checkBoxes = document.getElementsByClassName('checkList');

    if (checkBoxes.length !== 0) {
      for (let checkBox of checkBoxes) {
        checkBox.checked = false;
      }
      await totalAmount();
    }
  };

  //Total amount and save selected invoices to metadata
  const totalAmount = async () => {
    setSelectedMethod(0);

    setConfirmedMethod('');

    var checkBoxes = document.getElementsByClassName('checkList');
    var amount = 0;
    var amountUSD = 0;
    var amountBs = 0;
    var invoicesArray = [];
    var adaptedInvoicesArray = [];
    var bsInvoicesArray = [];

    if (checkBoxes.length !== 0) {
      for (let i in checkBoxes) {
        if (checkBoxes[i].checked === true) {
          amount += adaptedInvoices[i].balance;
          amountUSD += invoices[i].balance;
          amountBs += bsInvoices[i].balance;
          invoicesArray.push(invoices[i]);
          adaptedInvoicesArray.push(adaptedInvoices[i]);
          bsInvoicesArray.push(bsInvoices[i]);
        }
      }

      await checkControls();

      setTotal(amount);
      setTotalUSD(amountUSD);
      setTotalBs(amountBs);
      setVisible({ ...visible, method: true, cardBanesco: false, Ubiipagos: false });

      //Facturas
      setZohoInvoices(invoicesArray);
      setZohoAdaptedInvoices(adaptedInvoicesArray);
      setZohoBsInvoices(bsInvoicesArray);
    } else {
      setVisible({ ...visible, method: false, cardBanesco: false, Ubiipagos: false });
      setZohoInvoices([]);
      setZohoAdaptedInvoices([]);
      setZohoBsInvoices([]);
    }

    await checkAmount();
  };

  //Hide card three is no invoice selected
  const checkAmount = async () => {
    var checkBoxes = document.getElementsByClassName('checkList');
    var confirm = false;

    for (let checkBox of checkBoxes) {
      if (checkBox.checked === true) {
        confirm = true;
        break;
      }
    }

    if (confirm === false) {
      setVisible({ ...visible, banescoConfirm: false, cardBanesco: false, method: false, Ubiipagos: false });
    }
  };

  //Make selector work
  const checkControls = async () => {
    var checkBoxes = document.getElementsByClassName('checkList');
    var confirm = true;
    var counter = 0;

    while (confirm === true) {
      if (checkBoxes[counter].checked === true) {
        if (counter === checkBoxes.length - 1) {
          document.getElementById('chk-control').checked = true;
          confirm = false;
        } else {
          counter += 1;
        }
      } else {
        confirm = false;
        document.getElementById('chk-control').checked = false;
      }
    }
  };

  /*Handle Currency conversions*/
  const handleCurrency = async (currencyID) => {
    const currentCurrency = currencyData.filter((currency) => currency.CurrencyID === currencyID)[0];

    setCurrencyActiveOption(currencyID);

    setCurrencySymbol(currentCurrency.Symbol);

    setTotal(0);

    await retrieveInvoices(email.trim(), currencyID, 2);
  };

  /*Notifications*/
  const notify = (message, type, theme) => {
    toast(message, { type: type, theme: theme });
  };

  /*Confirm selected method*/
  const handleConfirmedMethod = () => {
    if (selectedMethod === 'Banesco pagos') {
      setVisible({
        ...visible,
        cardCurrency: false,
        cardTwo: false,
        banescoConfirm: true,
        cardBanesco: false,
        method: false,
        Ubiipagos: false,
        BNC: false,
      });
    } else if (selectedMethod === 'C2P, TDD, Pago Móvil, Zelle') {
      setVisible({
        ...visible,
        cardCurrency: false,
        cardTwo: false,
        banescoConfirm: false,
        cardBanesco: false,
        method: false,
        Ubiipagos: true,
        GoBack: true,
        BNC: false,
      });
    } else if (selectedMethod === 'Pago móvil') {
      setVisible({
        ...visible,
        cardCurrency: false,
        cardTwo: false,
        banescoConfirm: false,
        cardBanesco: false,
        method: false,
        Ubiipagos: false,
        GoBack: true,
        BNC: true,
      });
    }

    setConfirmedMethod(selectedMethod);
  };

  /*Show Banesco Button*/
  const show = () => {
    setVisible({
      ...visible,
      cardCurrency: false,
      cardTwo: false,
      banescoConfirm: false,
      cardBanesco: true,
      method: false,
      Ubiipagos: false,
      GoBack: true,
      BNC: false,
    });
  };

  /*Go Back*/
  const goBack = async () => {
    setTotal(0);
    setVisible({
      ...visible,
      cardCurrency: true,
      cardTwo: true,
      banescoConfirm: false,
      cardBanesco: false,
      method: false,
      Ubiipagos: false,
      GoBack: false,
      BNC: false,
    });
  };

  const reset = async () => {
    setTotal(0);
    setVisible({
      ...visible,
      cardOne: true,
      cardTwo: false,
      cardCurrency: false,
      cardBanesco: false,
      banescoConfirm: false,
      method: false,
      Ubiipagos: false,
      GoBack: false,
      BNC: false,
    });
  };

  return (
    <>
      <ToastContainer position="top-center" />

      {loading.page ? (
        <PageSpinner />
      ) : (
        <>
          <Header companyLogo={logoUrl} />

          <div style={mainContainer}>
            <Container>
              <FirstCard
                mainTitle={windowTitle}
                btnStyle={fetchBtn}
                cardTitle={emailLabel}
                getInvoices={retrieveInvoices}
                loading={loading.fetch}
                currency={currencyActiveOption}
              />

              <CurrencyCard
                visible={visible.cardCurrency}
                initialValue={currencyActiveOption}
                options={currencyOptions}
                handleCurrency={handleCurrency}
                loading={loading.currencyFetch}
              />

              {currencyActiveOption !== 0 ? (
                <SecondCard
                  visible={visible.cardTwo}
                  title={invoicesLabel}
                  invoices={adaptedInvoices}
                  totalTitle={totalLabel}
                  companyColor={companyColor}
                  totalAmount={totalAmount}
                  total={total}
                  currencySymbol={currencySymbol}
                />
              ) : (
                <></>
              )}

              <PaymentMethod
                visible={visible.method}
                confirmColor={fetchBtn.color}
                paymentMethods={paymentMethods}
                handleMethod={(e) => setSelectedMethod(e.target.value)}
                currencyActiveOption={currencyActiveOption}
                confirmPaymentMethod={handleConfirmedMethod}
              />

              {confirmedMethod === 'Banesco pagos' ? (
                <BanescoConfirmation
                  visible={visible.banescoConfirm}
                  companyColor={companyColor}
                  amount={total.toFixed(2)}
                  setUserId={setUserId}
                  showBanescoButton={show}
                  notify={notify}
                />
              ) : (
                <></>
              )}

              {visible.cardBanesco === true && BanescoActive === 1 ? (
                <Banesco
                  visible={visible.cardBanesco}
                  APIKey={BanescoAPIKey}
                  secret={BanescoSecret}
                  amount={total}
                  amountUSD={totalUSD}
                  cedula={userId}
                  companyAPIKey={apiKey}
                  currencyData={currencyData}
                  zohoInvoices={zohoInvoices}
                  zohoAdaptedInvoices={zohoAdaptedInvoices}
                  zohoCustomerId={zohoCustomerId}
                  metadata={metadata}
                  adaptedMetadata={adaptedMetadata}
                  paymentDescription={BanescoPaymentDescription}
                  addressQA={BanescoPaymentAddressQA}
                  addressPROD={BanescoPaymentAddressPROD}
                  companyLogo={logoUrl}
                  company={publicName}
                />
              ) : visible.cardBanesco === true && BanescoActive === 0 ? (
                <BanescoNotActive />
              ) : (
                <></>
              )}

              {confirmedMethod === 'C2P, TDD, Pago Móvil, Zelle' && ubiipagosConfig.isActive && visible.Ubiipagos ? (
                ['local', 'develop'].includes(environment) ? (
                  <UbiipagosD
                    visible={visible.Ubiipagos}
                    currency={currencyActiveOption}
                    zohoBsInvoices={zohoBsInvoices}
                    amount={totalBs}
                    amountUSD={totalUSD}
                    concept={ubiipagosConfig.concept}
                    clientId={ubiipagosConfig.clientId}
                    ubiiStyle={JSON.parse(ubiipagosConfig.style)}
                    currencies={currencyData}
                    companyAPIKey={apiKey}
                    zohoInvoices={zohoInvoices}
                    zohoAdaptedInvoices={zohoAdaptedInvoices}
                    zohoCustomerId={zohoCustomerId}
                    metadata={metadata}
                    adaptedMetadata={adaptedMetadata}
                  />
                ) : (
                  <UbiipagosP
                    visible={visible.Ubiipagos}
                    currency={currencyActiveOption}
                    zohoBsInvoices={zohoBsInvoices}
                    amount={totalBs}
                    amountUSD={totalUSD}
                    concept={ubiipagosConfig.concept}
                    clientId={ubiipagosConfig.clientId}
                    ubiiStyle={JSON.parse(ubiipagosConfig.style)}
                    currencies={currencyData}
                    companyAPIKey={apiKey}
                    zohoInvoices={zohoInvoices}
                    zohoAdaptedInvoices={zohoAdaptedInvoices}
                    zohoCustomerId={zohoCustomerId}
                    metadata={metadata}
                    adaptedMetadata={adaptedMetadata}
                  />
                )
              ) : visible.Ubiipagos === true && ubiipagosConfig.isActive === 0 ? (
                <UbiiNotActive />
              ) : (
                <></>
              )}

              {confirmedMethod === 'Pago móvil' && BNCActive === 1 && visible.BNC === true ? (
                <BNC
                  visible={visible.BNC}
                  amount={total}
                  amountUSD={totalUSD}
                  companyAPIKey={apiKey}
                  currencyData={currencyData}
                  zohoInvoices={zohoInvoices}
                  zohoAdaptedInvoices={zohoAdaptedInvoices}
                  email={email}
                  zohoCustomerId={zohoCustomerId}
                  zohoCustomerName={zohoCustomerName}
                  zohoCustomerLastName={zohoCustomerLastName}
                  metadata={metadata}
                  adaptedMetadata={adaptedMetadata}
                  company={publicName}
                  companyColor={companyColor}
                  notify={notify}
                  reset={reset}
                  BNCBankList={BNCBankList}
                />
              ) : visible.BNC === true && BNCActive === 0 ? (
                <BNCNotActive />
              ) : (
                <></>
              )}

              {visible.GoBack ? (
                <div className="mt-4" style={{ textAlign: 'center' }}>
                  <Button outline style={{ width: '440px' }} onClick={goBack}>
                    {lang('GO_BACK')}
                  </Button>
                </div>
              ) : (
                <></>
              )}

              <Footer />
            </Container>
          </div>
        </>
      )}
    </>
  );
}

export default Invoices;
