import React, { useState } from 'react';
import { Card, CardTitle, CardBody, Input, Row, Col, Button } from 'reactstrap';
import lang from './../lang';
import Select from "react-select";

/*Styles*/
const cMainStyle = {
  border: 0,
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  borderRadius: '8px'
};

const cTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  textAlign: 'center',
  height: '100%'
};

const labelStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  marginBottom: 0
};

function BanescoConfirmation({ companyColor, amount, setUserId, showBanescoButton, notify, visible }) {
  const [clientType, setClientType] = useState(null);
  const [clientId, setClientId] = useState(null);

  /*Variables*/
  const options = [
    { value: 'V', label: 'V' },
    { value: 'E', label: 'E' },
    { value: 'J', label: 'J' },
    { value: 'G', label: 'G' }
  ];

  /*Functions*/
  const confirmBanescoPayment = async() => {
    if(clientType === null) {
      await notify(lang("SELECT_CLIENT_TYPE"), "info", "colored");
    }
    else if (clientId === null || clientId.trim().length === 0) {
      await notify(lang("ENTER_CLIENT_ID"), "info", "colored");
    }
    else {
      const userId = clientType.trim() + clientId.trim();
      setUserId(userId);
      showBanescoButton();
    }
  };

  return (
    <>
      {
        (visible === true) ?

        <Card style={cMainStyle} className="mt-3">

          <CardBody>
            <CardTitle style={cTitleStyle}>{lang("BANESCO_CONFIRMATION")}</CardTitle>

            <Row>
              <Col>
                <label style={labelStyle}>{lang("AMOUNT")}</label>

                <Input
                  disabled
                  value={amount}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <label style={labelStyle}>{lang("USER_ID")}</label>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <Select
                  placeholder={lang("CLIENT_TYPE")}
                  options={options}
                  defaultValue={clientType}
                  onChange={(opt) => setClientType(opt.value)}
                />
                
              </Col>

              <Col xs={8}>
                <Input
                  style={{textTransform: 'uppercase'}}
                  onChange={(val) => setClientId(val.target.value)}
                />
              </Col>
            </Row>

            <Row className="text-center mt-3">
              <Col>
                <Button
                  style={{ backgroundColor: companyColor }}
                  onClick={() => confirmBanescoPayment()}
                >
                  {lang("CONFIRM")}
                </Button>
              </Col>
            </Row>

          </CardBody>
        </Card>
        :
        <></>
      }

    </>
  );
}

export default BanescoConfirmation;