/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { initUbii } from '@ubiipagos/boton-ubii-dc';
import uniqid from 'uniqid';

import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { registerUbiipagosPayment } from '../api/ubiipagos';
import lang from '../lang';

const cardStyle = {
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  marginTop: '16px',
  border: 0,
  borderRadius: '8px',
};

const cardTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  height: '100%',
  textAlign: 'center',
};

function UbiipagosD({ visible, currency, amount, amountUSD, concept, clientId, ubiiStyle, currencies, companyAPIKey, zohoInvoices, zohoAdaptedInvoices, zohoCustomerId, metadata, adaptedMetadata }) {

  useEffect(() => {
    init();
  }, []);

  /**
   * Function for rounding an amount to two decimals
   */
  const roundAmount = async (amount) => (Math.round((amount + Number.EPSILON) * 100) / 100).toFixed(2);

  const paymentHandler = async (response) => {
    const {
      ref: ubiiRef,
      order: ubiiOrder,
      orderID: ubiiOrderId,
      codR: ubiiCodR,
      codS: ubiiCodS,
      m: ubiiMAmount,
      currency: ubiiCurrency,
      method: ubiiMethod,
      tipo_pago: ubiiTipoPago,
      M: ubiiMMessage,
      R: ubiiR,
      date: ubiiDate,
      trace: ubiiTrace,
      bank: ubiiBank
    } = response.data

    /* Ubiipagos metadata to json */
    const ubiiMetadata = JSON.stringify(response);

    /* Zoho data to json */
    const zohoInvoicesJSON = JSON.stringify(zohoInvoices)
    const zohoAdaptedInvoicesJSON = JSON.stringify(zohoAdaptedInvoices)
    const metadataJSON = JSON.stringify(metadata)
    const adaptedMetadataJSON = JSON.stringify(adaptedMetadata)

    //Could be updated passing the current currency to the component
    const exchangeRate = currencies.filter((currency) => currency.CurrencyID === 2)[0].ExchangeBs;

    const roundedAmountUSD = Number(await roundAmount(amountUSD));
    const roundedAmountBs = Number(await roundAmount(amount));

    /* Ubiipagos default data */
    const data = {
      ubiiRef,
      ubiiOrder,
      ubiiOrderId,
      ubiiCodR,
      ubiiCodS,
      ubiiMAmount,
      ubiiCurrency,
      ubiiMethod,
      ubiiTipoPago,
      ubiiMMessage,
      ubiiR,
      ubiiDate,
      ubiiTrace,
      ubiiBank,
      ubiiMetadata,
      exchangeRate,
      amountUSD: roundedAmountUSD,
      amountBs: roundedAmountBs,
      zohoInvoices: zohoInvoicesJSON,
      zohoAdaptedInvoices: zohoAdaptedInvoicesJSON,
      zohoCustomerId,
      metadata: metadataJSON,
      adaptedMetadata: adaptedMetadataJSON,
    };

    await registerUbiipagosPayment(companyAPIKey, data);
  };

  const init = async () => {
    const initUbiiProps = {
      amount_ds: await roundAmount(amountUSD),
      amount_bs: await roundAmount(amount),
      concept,
      principal: currency === 1 ? 'bs' : 'ds',
      clientId,
      orderId: uniqid()
    };

    //ubiiStyle as last parameter is optional
    initUbii("ubiiboton", initUbiiProps, paymentHandler);
  };

  return (
    <>
      {
        (visible === true) ?
          <Card style={cardStyle}>
            <CardBody>
              <CardTitle style={cardTitleStyle}>{lang("UBIIPAGOS_BUTTON")}</CardTitle>
              <div style={{ textAlign: 'center' }}>
                <Button id="ubiiboton"></Button>
              </div>
            </CardBody>
          </Card>
        :
        <></>
      }
    </>
  )
}

export default UbiipagosD;