import CryptoJS from 'crypto-js';

export function getToken(index) {
  return window.sessionStorage.getItem(index);
};

export function setToken(index, value) {
  window.sessionStorage.setItem(index, value);
};

export function deleteToken(index) {
  window.sessionStorage.removeItem(index);
};

export function getPaymentDetails() {
  const payloadEncoded = window.sessionStorage.getItem('paymentPayload');
  const encodedString = CryptoJS.enc.Base64.parse(payloadEncoded);
  const payment = JSON.parse(CryptoJS.enc.Utf8.stringify(encodedString));

  const { publicName: name, companylogo: logo, transactionId: transaction, amountUSD: amount } = payment;

  const jsn = {
    "name": name,
    "logo": logo,
    "transaction": transaction,
    "amount": amount
  };

  return jsn;
};
