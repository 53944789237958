const spanish = {
  BNC_ENTER_BANKNAME: 'Por favor, ingrese el nombre del banco desde donde se realizó el pago.',
  BANK_NAME: 'Nombre del banco desde donde se realizó el pago',
  SELECT: 'Seleccionar',
  AMOUNT: 'Monto',
  AVAILABLE_ACCOUNTS: 'Cuentas disponibles',
  BANK_REFERENCE: 'Referencia bancaria:',
  BANESCO_BUTTON: 'Botón de Pago Banesco',
  BANESCO_CONFIRMATION: 'Confirme sus datos para realizar el pago Banesco.',
  BANESCO_NOT_ACTIVE: 'El botón de pago de Banesco no está disponible por el momento, disculpe los inconvenientes.',
  UBIIPAGOS_BUTTON: 'Botón de Pago Ubiipagos',
  BNC_CONFIRMATION: 'Confirme sus datos para registrar su pago BNC.',
  BNC_NOT_ACTIVE: 'El método de pago con BNC no está disponible por el momento, disculpe los inconvenientes.',
  BENEFICIARY_FULL_NAME: 'Nombre y apellido del Beneficiario:',
  BENEFICIARY_PHONE: 'Número del Beneficiario:',
  BENEFICIARY_EMAIL: 'Correo electrónico del Beneficiario:',
  BENEFICIARY_BANK_ACCOUNT: 'Cuenta bancaria del Beneficiario:',
  BENEFICIARY_PAYMENT_REFERENCE: 'Número de referencia del pago:',
  BNC_ENTER_CELL_PHONE: 'Por favor, ingrese el número de teléfono celular del beneficiario.',
  BNC_SELECT_CLIENT_TYPE: 'Por favor, seleccione el tipo de cliente.',
  BNC_ENTER_ID: 'Por favor, ingrese su número de cédula de identidad personal.',
  BNC_BANK_ACCOUNT: 'Por favor, ingrese el número de cuenta bancaria del beneficiario.',
  BNC_PAYMENT_REFERENCE: 'Por favor, ingrese la referencia de pago del beneficiario.',
  BNC_INVALID_CELL_PHONE: 'El formato del número de teléfono celular no es válido.',
  BNC_ENTER_OPERATOR: 'Por favor, ingrese su operadora móvil.',
  BNC_INVALID_ID: 'El formato del número de cédula de identidad personal no es válido.',
  BNC_SUCCESS: 'Su pago se ha registrado con éxito',
  BNC_ERROR: 'Ha ocurrido un error al registrar su pago, por favor intente nuevamente.',
  BNC_PAYMENT_EXISTS: 'Esta referencia de pago ya ha sido verificada y procesada.',
  BILLS: 'Facturas a pagar:',
  CLIENT_DOES_NOT_EXIST: 'El cliente no existe en el sistema.',
  CLIENT_TYPE: 'Cliente',
  CONFIRM: 'Confirmar',
  COPIED: 'Copiado',
  CONTINUE: 'Continuar',
  CURRENCIES_ERROR: 'Ha ocurrido un error al obtener la información de divisas disponibles.',
  DENIED_REQUEST: 'Solicitud denegada, comuníquese con el proveedor de servicios.',
  EMAIL: 'Email:',
  ENTER_CLIENT_ID: 'Ingrese su número de cédula de identidad personal.',
  ERROR_OCCURRED: 'Ha ocurrido un error, por favor intente nuevamente.',
  GO_BACK: 'Regresar',
  HOLDER: 'Titular:',
  IMPORTANT_INFORMATION: 'Información Importante',
  INVALID_EMAIL: 'El email no es válido.',
  INVALID_REQUEST: 'La solicitud no es válida.',
  INVOICES: 'Facturas',
  MULTIPLE_USERS: 'Múltiples usuarios con el mismo email, comuníquese con el proveedor de servicios.',
  NAME: 'Nombre:',
  NO_INVOICES: 'El cliente no tiene facturas pendientes por pago.',
  NO_ACTIVE_CURRENCIES: 'Esta compañía no tiene divisas configuradas.',
  PAGE_NOT_FOUND: 'Not found',
  PAYMENT_DETAILS: 'Detalles del pago',
  PAYMENT_FAILED: '¡Su pago no fue procesado!',
  PAYMENT_METHOD: 'Selecciona un método de pago',
  PAYMENT_METHOD_NOT_AVAILABLE: 'No hay métodos de pagos disponibles para la divisa seleccionada.',
  PAYMENT_SUCCESS: '¡Su pago se ha realizado con éxito!',
  REQUEST_NOT_PROCESSED: 'La solicitud no pudo ser procesada, intente nuevamente más tarde.',
  SEARCH_STATUS: 'Consultar estatus',
  SELECT_CURRENCY: 'Seleccionar divisa',
  SELECT_CLIENT_TYPE: 'Por favor seleccione el tipo de cliente.',
  TRY_AGAIN: 'Intentar de nuevo',
  UBIIPAGOS_NOT_ACTIVE: 'El botón de pago de Ubiipagos no está disponible por el momento, disculpe los inconvenientes.',
  USER_ID: 'Cédula / RIF',
  TYPE_NUMBER: 'Número de telefono:',
  TYPE_OF_OPERATOR: 'Operador',
  VERIFICATION_PROCESS: 'Proceso de Verificación',
  ZELLE_NOT_ACTIVE:
    'En estos momentos no se encuentra disponible el pago por la plataforma, disculpe las molestias ocasionadas.',
  ZELLE_PAYMENT_STATUS: 'Estatus de pago Zelle',
  ZELLE_PAYMENT_TITLE: 'Registro de pago Zelle',
  ZELLE_REFERENCE: 'Referencia Zelle',
  ZELLE_REFERENCE_ALREADY_EXISTS: 'La referencia Zelle ya existe.',
};

const english = {
  BNC_ENTER_BANKNAME: 'Please enter the name of the bank from which the payment is made.',
  BANK_NAME: 'Name of the bank from which the payment is made',
  SELECT: 'Select',
  AMOUNT: 'Amount',
  AVAILABLE_ACCOUNTS: 'Available accounts',
  BANK_REFERENCE: 'Bank reference:',
  BANESCO_BUTTON: 'Banesco Payment Button',
  BANESCO_CONFIRMATION: 'Confirm your data to make the Banesco payment.',
  BANESCO_NOT_ACTIVE: 'The Banesco payment button is not available at the moment, sorry for the inconvenience.',
  UBIIPAGOS_BUTTON: 'Ubiipagos Payment Button',
  BNC_CONFIRMATION: 'Confirm your data to register your BNC payment.',
  BNC_NOT_ACTIVE: 'The BNC payment method is not available at the moment, sorry for the inconvenience.',
  BENEFICIARY_FULL_NAME: "Beneficiary's full name:",
  BENEFICIARY_PHONE: "Beneficiary's phone number:",
  BENEFICIARY_EMAIL: "Beneficiary's email:",
  BENEFICIARY_BANK_ACCOUNT: "Beneficiary's bank account:",
  BENEFICIARY_PAYMENT_REFERENCE: 'Reference number of the payment',
  BNC_ENTER_CELL_PHONE: "Please enter the beneficiary's cell phone number.",
  BNC_SELECT_CLIENT_TYPE: "Please select the client's type.",
  BNC_ENTER_ID: 'Please enter your personal identity card number.',
  BNC_BANK_ACCOUNT: "Please enter the beneficiary's bank account number.",
  BNC_PAYMENT_REFERENCE: "Please enter the beneficiary's payment reference.",
  BNC_INVALID_CELL_PHONE: 'The format of the cell phone number is not valid.',
  BNC_INVALID_ID: 'The format of the personal identity card number is not valid.',
  BNC_SUCCESS: 'Your payment has been registered successfully.',
  BNC_ERROR: 'An error has occurred while registering your payment, please try again.',
  BNC_PAYMENT_EXISTS: 'This payment reference has already been verified and processed.',
  BILLS: 'Bills to pay:',
  CLIENT_DOES_NOT_EXIST: 'The client does not exist in the system.',
  CLIENT_TYPE: 'Client',
  CONFIRM: 'Confirm',
  COPIED: 'Copied',
  CONTINUE: 'Continue',
  CURRENCIES_ERROR: 'An error has occurred while obtaining the available currency information.',
  DENIED_REQUEST: 'Denied request, please contact the service provider.',
  EMAIL: 'Email:',
  ENTER_CLIENT_ID: 'Enter your personal identity card number.',
  ERROR_OCCURRED: 'An error has occurred, please try again.',
  GO_BACK: 'Go Back',
  HOLDER: 'Holder:',
  IMPORTANT_INFORMATION: 'Important information',
  INVALID_EMAIL: 'The email is not valid.',
  INVALID_REQUEST: 'The request is not valid.',
  INVOICES: 'Invoices',
  MULTIPLE_USERS: 'Multiple users with the same email, please contact the service provider.',
  NAME: 'Name:',
  NO_INVOICES: 'The client has no pending invoices for payment.',
  NO_ACTIVE_CURRENCIES: 'This company has no currencies configured.',
  PAGE_NOT_FOUND: 'Not found',
  PAYMENT_DETAILS: 'Payment details',
  PAYMENT_FAILED: 'Your payment was not processed!',
  PAYMENT_METHOD: 'Select a payment method',
  PAYMENT_METHOD_NOT_AVAILABLE: 'There are no payment methods available for the selected currency.',
  PAYMENT_SUCCESS: 'Your payment has been made successfully!',
  REQUEST_NOT_PROCESSED: 'The request could not be processed, please try again later.',
  SEARCH_STATUS: 'Check status',
  SELECT_CURRENCY: 'Select currency',
  SELECT_CLIENT_TYPE: "Please select the client's type",
  TRY_AGAIN: 'Try again',
  UBIIPAGOS_NOT_ACTIVE: 'Ubiipagos payment button is not available at the moment, sorry for the inconvenience.',
  USER_ID: 'Id',
  VERIFICATION_PROCESS: 'Verification Process',
  ZELLE_NOT_ACTIVE: 'Payment through the platform is not currently available, sorry for the inconvenience.',
  ZELLE_PAYMENT_STATUS: 'Zelle payment status',
  ZELLE_PAYMENT_TITLE: 'Zelle payment Record',
  ZELLE_REFERENCE: 'Zelle Reference',
  ZELLE_REFERENCE_ALREADY_EXISTS: 'The Zelle reference already exists.',
};

const langServer = {
  es: spanish,
  en: english,
  default: spanish,
};

export default function lang(message) {
  let langMessage;

  if (message === undefined || message === null || message === '') {
    langMessage = langServer['default']['EMPTY_INIT'];
    return langMessage;
  } else {
    langMessage = langServer['default'][message];
    return langMessage;
  }
}
