import React from 'react';
import { Card, CardTitle, CardBody, Spinner } from 'reactstrap';
import Select, { components } from "react-select";

import lang from '../lang';


/*Styles*/
const cMainStyle = {
  border: 0,
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  borderRadius: '8px'
};

const cTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  textAlign: 'center',
  height: '100%'
};

function CurrencyCard({ visible, initialValue, options, handleCurrency, loading }) {
  /*Variables*/
  const defaultValue = options.filter(option => option.value === initialValue);

  const { Option, SingleValue } = components;

  /*Icon and values for Select Component*/
  const iconOption = props => (
    <Option  {...props}>
      <img src={props.data.icon} style={{ width: 30, marginRight: '15px' }} alt={props.data.label} />

      {props.data.label}
    </Option>

  );

  const valueOption = (props) => (
    <SingleValue {...props}>
      <img src={props.data.icon} style={{ width: 30, marginRight: '15px' }} alt={props.data.label} />

      {props.data.label}
    </SingleValue>
  );


  return (
    <>
      {
        (visible === true) ?

          <Card style={cMainStyle} className="mt-3">

            <CardBody>

              {
                (!loading) ?
                <>
                  <CardTitle style={cTitleStyle}>{lang("SELECT_CURRENCY")}</CardTitle>

                  <div style={{ fontWeight: 'bold' }}>

                    {
                      (options.length === 0) ?
                        <div className="text-center">
                          <label className="text-center"> {lang("NO_ACTIVE_CURRENCIES")} </label>
                        </div>
                        :
                        <Select
                          placeholder={lang("SELECT_CURRENCY")}
                          options={options}
                          components={{ Option: iconOption, SingleValue: valueOption }}
                          defaultValue={defaultValue}
                          onChange={(opt) => handleCurrency(opt.value)}
                        />
                    }

                  </div>
                </>
                :
                <div className="text-center">
                  <Spinner type="border" children="" />
                </div>
              }
            </CardBody>
          </Card>
          :
          <></>
      }
    </>
  );
}

export default CurrencyCard;