import React, { useState, useEffect } from 'react';

import { Container, Card, CardBody, CardTitle, Button } from 'reactstrap';

import Header from './../components/Header';

import FailedImage from './../assets/icons/failed-icon.svg';

import lang from '../lang';

import * as token from './../utils/token';


function Failed() {
  /*State variables*/
  const [publicName, setPublicName] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);

  /*Styles*/
  const mainContainer = {
    width: '100%',
    maxWidth: '480px',
    margin: '0 auto',
    borderRadius: '2px',
    backgroundColor: '#F3F3F7',
    marginTop: '30px',
    display: 'flex',
  };

  const cardStyle = {
    boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
    paddingBottom: '0px',
    border: 0,
    borderRadius: '8px',
    padding: '20px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%'
  };

  const failedIconStyle = {
    width: '200px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    marginTop: '5px'
  };

  const messageStyle = {
    fontFamily: 'OpenSans-Bold, sans-serif',
    fontWeight: 600,
    fontSize: '20px',
    color: '#41434E',
    marginBottom: '30px'
  };

  const tryAgainStyle = {
    backgroundColor: '#0071BC',
    width: '100%'
  };

  /*Functions*/

  const getPaymentDetails = async () => {
    const { name, logo } = token.getPaymentDetails();
    setPublicName(name);
    setCompanyLogo(logo);
  };

  const tryAgainBackToHome = () => {
    window.location.href = `/${publicName}`;
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);


  return (
    <>
      <Header companyLogo={companyLogo} />

      <Container style={mainContainer}>

        <Card style={cardStyle}>
          <CardTitle>
            <img alt="..." src={FailedImage} style={failedIconStyle} />
          </CardTitle>

          <CardBody>
            <div className="text-center">
              <label style={messageStyle}> {lang("PAYMENT_FAILED")} </label>
            </div>
          </CardBody>

          <Button style={tryAgainStyle} onClick={tryAgainBackToHome}>{lang("TRY_AGAIN")}</Button>
        </Card>
      </Container>
    </>
  );
}

export default Failed;