import axios from "axios";
import * as CONS from "./constants.js";
import { setHeaders } from '../utils/headers';

export const registerUbiipagosPayment = async (apiKey, body) => {
  const headers = await setHeaders();
  headers['api_key'] = apiKey;
  
  try {
    return await axios.post(CONS.registerUbiipagos, body, { headers: headers });
  } 
  catch (error) {
    return error.response;
  }
}