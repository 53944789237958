import axios from "axios";
import * as CONS from "./constants.js";
import { setHeaders } from '../utils/headers';

export async function registerBanescoPayment(apiKey, body) {
  const headers = await setHeaders();
  headers["api_key"] = apiKey;

  try {
    const response = await axios.post(CONS.registerBanesco, body, { headers: headers });
    return response;
  }
  catch (error) {
    console.log("ERROR_REGISTER_BANESCO_PAYMENT ===>", error.response);
    return error.response;
  }
}