import React from 'react';
import { Container, Input, Card, CardTitle, CardBody, Form, FormGroup, Label, Button } from 'reactstrap';
import PaymentMethodNotActive from './PaymentMethodNotActive';
import lang from '../lang';

/*Styles*/
const cMainStyle = {
  border: 0,
  boxShadow: '3px 3px 21px -9px rgba(107,107,107,1)',
  paddingBottom: '0px',
  borderRadius: '8px'
};

const cTitleStyle = {
  fontFamily: 'OpenSans-Medium, sans-serif',
  color: '#41434E',
  textAlign: 'center',
  height: '100%'
};

function PaymentMethod({ visible, confirmColor, currencyActiveOption, paymentMethods, handleMethod, confirmPaymentMethod }) {

  const isCurrentCurrencyAvailable = paymentMethods.some((method) => method.Currencies.includes(currencyActiveOption));

  const methodTitle = !isCurrentCurrencyAvailable ? lang('PAYMENT_METHOD_NOT_AVAILABLE') : lang('PAYMENT_METHOD');

  return (
    <>
      {
        (visible === true) ?
          (isCurrentCurrencyAvailable === true) ?

            <Card className="mt-3" style={cMainStyle}>
              <CardBody>
                <CardTitle style={cTitleStyle}>{methodTitle}</CardTitle>

                <Container>
                  <Form>
                    <FormGroup tag="fieldset">
                      {
                        paymentMethods.filter(currency => currency.Currencies.includes(currencyActiveOption)).map((method, index) => (
                          <FormGroup check key={index}>
                            <Input
                              key={index}
                              type="radio"
                              value={method.MethodName}
                              name="paymentMethods"
                              onClick={(e) => handleMethod(e)}
                              style={{ boxShadow: "none" }}
                            />
                            <Label style={{ fontWeight: "bold" }} check>{method.MethodName}</Label>
                          </FormGroup>
                        ))
                      }
                    </FormGroup>
                  </Form>

                  <div className="text-center">
                    <Button
                      style={{ backgroundColor: confirmColor, outline: "none" }}
                      onClick={confirmPaymentMethod}
                    >
                      {lang("CONFIRM")}
                    </Button>
                  </div>
                </Container>
              </CardBody>
            </Card>
          :
          <PaymentMethodNotActive /> 
        :
        <></>
      }
    </>
  );
}

export default PaymentMethod;